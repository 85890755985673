import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
import { downTemplate } from '@/utils/download';

/**
 * 产品分页查询
 * @param params
 */
export function findProductPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/page',
    method: 'get',
    params,
  });
}

/**
 * 创建产品
 * @param params
 */
export function createProduct(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/create',
    method: 'post',
    data: params,
  });
}

/**
 * 下载产品模版
 */
export function downProductTemplate() {
  downTemplate('vlink.productTsl', envInfo.bgApp.thingsPath);
}

/**
 * 复制产品
 * @param params
 */
export function copyProduct(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/copy',
    method: 'post',
    data: params,
  });
}

/**
 * 更新产品
 * @param params
 */
export function updateProduct(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update',
    method: 'post',
    data: params,
  });
}

/**
 * 删除产品
 * @param params
 */
export function deleteProductById(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/delete/' + params.productId,
    method: 'delete',
  });
}

/**
 * 批量删除产品
 * @param params
 */
export function batchDeleteProduct(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/delete/batch',
    method: 'put',
    data: params,
  });
}

/**
 * 更新产品动态注册开关
 * @param params
 */
export function updateDynamicRegistration(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update/dynamicRegistration',
    method: 'post',
    data: params,
  });
}

/**
 * 更新产品共享状态
 * @param params
 */
export function updateproductShareState(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update/shared',
    method: 'post',
    data: params,
  });
}

/**
 * 查询共享产品列表
 * @param productName(非必填)
 *
 */
export function getSharedProductList(params) {
  return request({
    url: envInfo.bgApp.iotPath + '/productShared/list',
    method: 'get',
    params,
  });
}

/**
 * 加载产品列表
 * @param params
 */
export function getProductList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/list',
    method: 'get',
    params,
  });
}

/**
 * 查询产品详情
 * @param params
 */
export function findProductDetail(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/detail',
    method: 'get',
    params,
  });
}

/**
 * 查询产品证书
 * @param params
 */
export function viewCert(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/cert',
    method: 'get',
    params,
  });
}

/**
 * 查询产品tsl
 * @param params
 */
export function findTsl(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/tsl',
    method: 'get',
    params,
  });
}

/**
 * 更新物模型模块Tsl json
 * @param params
 */
export function updateTsl(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update/tsl',
    method: 'post',
    data: params,
  });
}

/**
 * 新增脚本
 * @param params
 */
export function createScript(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/create',
    method: 'post',
    data: params,
  });
}

/**
 * 更新脚本
 * @param params
 */
export function updateScript(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/update',
    method: 'post',
    data: params,
  });
}

/**
 * 查询脚本
 * @param params
 */
export function findScript(productKey) {
  return request({
    url: `${envInfo.bgApp.thingsPath}/product/script/get?productKey=${productKey}`,
    //url: envInfo.bgApp.thingsPath + '/product/script/get',
    method: 'get',
    //data: params // 我试下另一种写法
  });
}

/**
 * 根据productKey获取状态为 运行和草稿 的脚本
 * @param params
 */
export function findProductKeyByScript(productKey) {
  return request({
    url: `${envInfo.bgApp.thingsPath}/product/script/getByProductKey?productKey=${productKey}`,
    method: 'get',
  });
}
/**
 * 脚本调试接口
 * scriptLanguage, scriptContent, data, DataMethod
 * @param params
 */
export function debugScript(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/debugScript',
    method: 'post',
    data: params,
  });
}

/**
 * 脚本上报数据调试接口
 * @param  params
 * @returns
 */
export function debugReportScript(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/debugReportScript',
    method: 'post',
    data: params,
  });
}

/**
 * 脚本接收数据调试接口
 * @param  params
 * @returns
 */
export function debugReceiveScript(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/debugReceiveScript',
    method: 'post',
    data: params,
  });
}

/**
 * 根据productId更新产品描述
 * @param params
 */
export function updateDescriptionByProductId(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update',
    method: 'post',
    data: params,
  });
}

/**
 * 根据productId和tagName删除标签
 * @param params
 */
export function deleteTagByTagNameAndProductId(productId, tagName) {
  return request({
    url:
      envInfo.bgApp.thingsPath +
      '/product/tagDelete/' +
      productId +
      '/' +
      tagName,
    method: 'delete',
  });
}

/**
 * 根据productId和tagName添加标签
 * @param params
 */
export function addTagByTagNameAndProductId(productId, tagName) {
  return request({
    url:
      envInfo.bgApp.thingsPath + '/product/tagAdd/' + productId + '/' + tagName,
    method: 'post',
  });
}

/**
 * 创建产品Ota
 * @param params
 */
export function createProductOta(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota',
    method: 'post',
    data: params,
  });
}

/**
 * 修改产品Ota
 * @param params
 */
export function updateProductOta(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota',
    method: 'put',
    data: params,
  });
}

/**
 * 删除产品Ota
 * @param params
 */
export function deleteOtaById(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota/' + params.otaId,
    method: 'delete',
  });
}

/**
 * 删除产品Ota
 * @param params
 */
export function batchDeleteProductOta(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota/delete/batch',
    method: 'put',
    data: params,
  });
}

/**
 * 查询产品Ota分页
 * @param params
 */
export function findOtaPage(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota/page',
    method: 'get',
    params,
  });
}

/**
 * 启用/禁用
 * @param params
 */
export function updateEnableStatus(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota/updateStatus',
    method: 'put',
    data: params,
  });
}

/**
 * 修改ota排序
 * @param params
 */
export function changeIndex4Ota(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/ota/changeIndex',
    method: 'put',
    data: params,
  });
}

/**
 * 创建自定义Topic
 * @param params
 */
export function createCustomTopic(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/customTopic/create',
    method: 'post',
    data: params,
  });
}

/**
 * 修改自定义Topic
 * @param params
 */
export function updateCustomTopic(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/customTopic/update',
    method: 'put',
    data: params,
  });
}

/**
 * 查询自定义Topic的列表
 * @param productKey
 */
export function findCustomTopicList(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/customTopic/findList',
    method: 'get',
    params,
  });
}

/**
 * 删除自定义Topic
 * @param params
 */
export function deleteCustomTopic(params) {
  return request({
    url:
      envInfo.bgApp.thingsPath +
      '/product/customTopic/delete/' +
      params.topicId,
    method: 'delete',
  });
}

/**
 * 脚本调试 - 查询模板json
 * @param productKey
 */
export function getTemplateJson(params) {
  console.log('xxx2', params);
  return request({
    url: envInfo.bgApp.thingsPath + '/product/script/getTemplateJson',
    method: 'get',
    params,
  });
}

/**
 * 修改产品扩展信息
 * @param productId
 * @param extendInfo
 */
export function updateProductExtendInfo(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/update/extendInfo',
    method: 'post',
    data: params,
  });
}

/**
 * author T
 * 
 * data 2020.6.1
 * 
 * 分页器mixins组件
 * 
 */
export default {
    data(){
        return {
            tableParams: {
            	current:1,//当前页
            	rowCount:10,//每页数
	            total:0,//列表总数
	            totalPage: 0,
            },
        }
    },
    
}

<!--查看智能设备 历史记录-->
<template>
  <div class="historylistWarp">
    <div class="h-title">{{ this.description }}</div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <!-- valueSlot -->
        <template v-slot:valueSlot>
          <el-select
            placeholder="关系运算符"
            style="display: inline-block; width: 30%"
            v-model="property.listQuery.operator"
            @change="operatorChange"
          >
            <el-option
              v-for="item in operatorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="上报值"
            v-model="property.listQuery.value"
            :disabled="valueDisable"
            style="display: inline-block; width: 30%; margin-left: 10px"
            @input="eventHandlerDebounce"
          >
          </el-input>
        </template>
      </finalTable>
    </div>
  </div>
</template>

<script>
import { findTsl } from "@/api/ruge/vlink/product/product";
import { findPropertyHistoryPage } from "@/api/ruge/vlink/device/device";
import finalTable from "@/components/FinalTable";
import moment from "moment";
import { debounce } from "lodash";
import handsRlink from "@/mixins/handsRlink.js";
export default {
  mixins: [handsRlink],
  components: { finalTable },
  props: {
    productKey: {
      type: String,
    },
    deviceName: {
      type: String,
    },
    moduleName: {
      type: String,
    },
    propertyName: {
      type: String,
    },
    factoryCode: {
      type: String,
    },
    description: {
      type: String,
    },
    currentDataType: {
      type: String,
      default: "decimal",
    },
  },
  watch: {
    productKey: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.productKey = value;
        this.findTsl();
      },
    },
    deviceName: {
      immediate: true,
      handler: function (value) {
        console.log("++++++++++", value, this.property.listQuery.deviceName);
        this.property.listQuery.deviceName = value;
      },
    },
    moduleName: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.moduleName = value;
        this.changeModule();
      },
    },
    propertyName: {
      immediate: true,
      handler: function (value) {
        this.property.listQuery.propertyName = value;
        // this.findPropertyHistoryPage();
      },
    },
    factoryCode: {
      immediate: true,
      handler: function (value) {
//      this.property.listQuery.factoryCode = value;
        // this.findPropertyHistoryPage();
      },
    },
    property: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
    currentDataType: {
      handler(val) {
        this.property.listQuery.dataType = val;
        this.findPropertyHistoryPage();
        console.log("currrent data type:", val);
        if (["int", "decimal"].includes(val)) {
          this.operatorList = this.operatorList.concat([
            {
              label: "大于",
              value: "gt",
            },
            {
              label: "小于",
              value: "lt",
            },
            {
              label: "大于等于",
              value: "gtOrEquals",
            },
            {
              label: "小于等于",
              value: "ltOrEquals",
            },
          ]);
        }
      },
      immediate: true,
    },
    valueDisable: {
      handler() {
        if (
          ["isNull", "isNotNull"].includes(this.property.listQuery.operator)
        ) {
          this.property.listQuery.value = "";
        }
      },
    },
  },
  computed: {
    valueDisable() {
      return ["isNull", "isNotNull"].includes(this.property.listQuery.operator);
    },
  },
  data() {
    return {
      /*
				* 运算符 
				  为空:isNull 
				  不为空:isNotNull 
				  大于:gt 
				  小于:lt 
				  大于等于:gtOrEquals 
				  小于等于:ltOrEquals 
				  等于:equals
				* */
      operatorList: [
        {
          label: "为空",
          value: "isNull",
        },
        {
          label: "不为空",
          value: "isNotNull",
        },
        {
          label: "等于",
          value: "equals",
        },
      ],
      eventHandlerDebounce: debounce(() => {
        if (!this.property.listQuery.operator) return;
        this.findPropertyHistoryPage();
      }, 500),
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "timestamp",
            label: this.$t("vlink.commons.reportTime"),
            width: "",
          },
          {
            prop: "value",
            label: this.$t("vlink.tsl.reported"),
            width: "",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          // value: {
          //   type: "input",
          //   label: "上报值",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入上报值",
          //   prefixIcon: "el-icon-search",
          // },
          value: {
            type: "slot",
            slotName: "valueSlot",
          },
          timestamp: {
            type: "dateRange",
            actionType: "goSearch",
            value: [new Date(moment().subtract(7, "days")).getTime(), null],
          },
        },
        // 表格内容配置
        detailConfig: {
          value: {
            type: "joinList",
            joinSymbol: "",
            joinList: ["value", "unit"],
          },
          timestamp: {
            type: "dateFormat",
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          // reportTime: {
          //   type: "dateRange",
          //   actionType: "goSearch",
          //   value: [new Date(moment().subtract(7, "days")).getTime(), null],
          // },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      tsl: {},
      moduleList: [],
      propertyList: [],
      property: {
        listLoading: false,
        list: [],
        total: 0,
        listQuery: {
          startTime: new Date(moment().subtract(7, "days")).getTime(),
          endTime: null,
          productKey: null,
          deviceName: null,
          moduleName: null,
          propertyName: null,
          dataType: null,
          operator: "equals",
          value: null,
          current: 1,
          rowCount: 10,
        },
      },
    };
  },
  methods: {
    operatorChange(operator) {
      // 如果操作符清空，则清掉value，查询
      if (!operator) {
        this.property.listQuery.value = "";
        this.findPropertyHistoryPage();
        return;
      }
      const { value } = this.property.listQuery;
      // 如果值不存在，并且不是为空/不为空
      if (!value && operator !== "isNull" && operator !== "isNotNull") return;
      this.findPropertyHistoryPage();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.timestamp && datas.params.timestamp.length > 0) {
          this.property.listQuery.startTime = datas.params.timestamp[0]
            ? new Date(datas.params.timestamp[0]).getTime()
            : null;
          this.property.listQuery.endTime = datas.params.timestamp[1]
            ? new Date(datas.params.timestamp[1]).getTime()
            : null;
          delete datas.params.timestamp;
        }
        this.property.listQuery = {
          ...this.property.listQuery,
          ...datas.params,
        };
        this.findPropertyHistoryPage();
      } else if (datas.type === "paginationChange") {
        this.property.listQuery.current = datas.params.current.page;
        this.property.listQuery.rowCount = datas.params.current.limit;
        this.initHistorylist();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            break;
          case "view":
            break;
          case "clear":
            break;
          case "setValue":
            break;
        }
      }
    },
    findTsl() {
      if (!this.property.listQuery.productKey) return;
      findTsl({
        productKey: this.property.listQuery.productKey,
      }).then((response) => {
        this.property.listQuery.current = 1;
        this.moduleList = [];
        this.propertyList = [];
        this.property.total = 0;
        this.property.list = [];

        this.tsl = response;
        if (this.tsl && this.tsl.modules && this.tsl.modules.length > 0) {
          this.tsl.modules.forEach((module) => {
            this.moduleList.push(module);
            if (
              module.moduleName == this.property.listQuery.moduleName &&
              module.properties &&
              module.properties.length > 0
            ) {
              // fill propertyList
              this.propertyList = module.properties;
            }
          });
        }
      });
    },
    changeModule() {
      this.property.listQuery.propertyName = null;
      // 显示模块下的属性列表
      if (
        this.property.listQuery.moduleName &&
        this.moduleList &&
        this.moduleList.length > 0
      ) {
        this.moduleList.forEach((module) => {
          if (
            module.moduleName == this.property.listQuery.moduleName &&
            module.properties &&
            module.properties.length > 0
          ) {
            // fill propertyList
            this.propertyList = module.properties;
          }
        });
      } else {
        this.propertyList = [];
      }
    },
    findPropertyHistoryPage() {
      this.property.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.initHistorylist();
    },
    startPicker(time) {
      this.property.listQuery.startTime = time.getTime();
    },
    endPicker(time) {
      this.property.listQuery.endTime = time.getTime();
    },
    async initHistorylist() {
      this.property.listLoading = true;
      if (["isNull", "isNotNull"].includes(this.property.listQuery.operator)) {
        this.property.listQuery.value = "";
      }
      let response = await this.findAlarmPropertyPage(this.property.listQuery);
      if (response) {
        this.dataset.pageVO.total = response.total;
        this.dataset.tableData = response.rows;
      }
      this.property.listLoading = false;
    },
  },
};
</script>

<style scoped lang="less">
.historylistWarp {
  .h-title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
  .title-line {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .title-detail-line {
      margin-right: 80px;
      .detail-title {
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000000;
      }
      .detail-content {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      & > div {
        height: 20px;
        line-height: 20px;
      }
    }
  }
}
</style>
/**
 * author T
 * 
 * data 2020.8.19
 * 
 * 查询条件组件和table组件的中转中心
 * 
 * 注意事项：topSearch 和  tableTitle  分别在页面created钩子函数里面赋值 就能达到每个页面的数据不一样了
 */
export default {
    data(){
        return {
            topSearch: [],//搜索条件设置的数据
            tableTitle:[],//表头设置的数据
            seachData:{},//搜索条件返回的数据
            tableData: [],//表格数据
            tbloading:false,
            rowQuery:true,
            rowFilterData:{},
        }
    },
    methods: {
        /**
         * seach组件初始化完后的赋值
         */
        initSeach(){
        	this.tbloading=true;
            this.initTable();
        },
        /**
         * 点击查询
         */
        seach(){
        	this.tbloading=true;
            if(this.tableParams){
                this.tableParams.current = 1;
            }
            this.initTable();
        },
        resetSeach(){
			for(let keys in this.seachData){
				if(this.seachData){
					let type=typeof(this.seachData[keys]);
					if(type=='string'||type=='number'){
						this.seachData[keys]='';
					}else{
						this.seachData[keys]=[];
					}
				}
				
			}
			this.seach();
		},
		showRowQuery(){
			this.rowQuery=!this.rowQuery;
		},
		refreshTable(){
			this.rowFilterData={};
			if(this.tableTitle){
				for(let item of this.tableTitle){
					if(item.queryInfo){
						item.queryInfo.value=null;
					}
				}
			}
			if(this.tableParams){
                this.tableParams.current = 1;
            }
			this.initTable();
		},
		rowSeach(val){
			this.rowFilterData={...val};
			if(this.tableParams){
        this.tableParams.current = 1;
      }
			this.backSetTbVals();
      this.initTable();
		},
		leaveTbVals(){
			//离开页面时候缓存页面查询数据
			if(this.tbBkSeach){
//				console.log('缓存页面数据',this.rowFilterData);
				let saveObj={
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
					...this.rowFilterData,
				}
				localStorage.setItem('tbSeachDatas',JSON.stringify(saveObj));
			}
			
		},
		backSetTbVals(){
			//返回的时候重新赋值
			if(this.tbBkSeach){
				let tbVals=localStorage.getItem('tbSeachDatas');
				if(tbVals){
					let valObjs=JSON.parse(tbVals);
					localStorage.removeItem('tbSeachDatas');
					this.rowFilterData={...valObjs};
					let _pArr=Object.keys(this.tableParams);
					for(let keys in valObjs){
						let val=valObjs[keys];
						this.injectTbTitleVals(keys,val);
						if(_pArr.indexOf(keys)>-1) this.tableParams[keys]=val;//赋值分页的参数。
					}
					delete this.rowFilterData.current;
					delete this.rowFilterData.rowCount;
//					console.log('返回重新赋值',tbVals);
				}
			}
			
		},
		injectTbTitleVals(key,vals){
			//作用于table2 行内查询条件
			for(let it of this.tableTitle){
				if(it.prop == key){
					if(it.queryInfo){
						it.queryInfo.value = vals;
						break;
					}
				}
			}
		},
  },
  beforeDestroy() {
  	this.leaveTbVals();
  	
  },
}
